import React from 'react';
import {observer} from "mobx-react";
import {Notification, notificationStore} from "../../stores/notification.store";
import {userService} from "../../api/user.api";
import {PasswordChanger} from "../../components/PasswordChanger";

class PasswordReset extends React.Component {
    constructor(props) {
        super(props);

        this.userId = props.match.params.userId;
        this.invitationCode = props.match.params.invitationCode;
        this.state = {
            isValidated: false,
            validPasswords: false,
            redirect: false
        };
		this.shouldRedirect = this.shouldRedirect.bind(this);
		this.changePassword = this.changePassword.bind(this);
    }

	shouldRedirect() {
		return this.state.redirect;
	}

	changePassword(password) {
		return userService.resetPasswordConfirm(this.userId, this.invitationCode, {
			password: password
		}).then(() => {
			this.setState({redirect: true});
            notificationStore.push(Notification.success("Password has been reset!"));
		});
	}

    render() {
		const shouldRedirect = this.shouldRedirect;
		const changePassword = this.changePassword;
		return (
			<PasswordChanger shouldRedirect={shouldRedirect} changePassword={changePassword} />
		)
    }
}

export default PasswordReset = observer(PasswordReset);

