import React from "react";
import {DEFAULT_SIZE, Pageable} from "../../components/Model/pageable.class";
import Pagination from "../../components/Pagination/pagination";
import {Tooltip, OverlayTrigger, Col, Row, Table} from "react-bootstrap";
import {userService} from "../../api/user.api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/free-solid-svg-icons";

export default class UsersList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageable: Pageable.createEmpty()
        };

        this.loadUsers = this.loadUsers.bind(this);
    }

    componentDidMount() {
        this.loadUsers(0);
    }

	loadUsers(page) {
		userService.getUsers(page, DEFAULT_SIZE)
            .then(pageable => {
                this.setState({
                    pageable: pageable
                })
            });
	}

    render() {

        const pageable = this.state.pageable;

        return (
            <>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th style={{"width": 40}}>#</th>
                                <th>User</th>
                                <th>Organization</th>
                                <th>Role</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {pageable.content.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{"width": 40}}>
                                            <b>{(index + 1) + (pageable.number) * DEFAULT_SIZE}</b>
                                        </td>
                                        <td>
                                            <b>{value.name} ({value.email})</b>
                                        </td>
                                        <td>
                                            <b>{value.organization}</b>
                                        </td>
                                        <td>
                                            <b>{value.role}</b>
                                        </td>
                                        <td>
											<DeleteUser id={value.id} reload={this.loadUsers}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        <Pagination className={"center"}
                                    pageable={pageable}
                                    onChangePageEvent={this.loadUsers}/>
                    </Col>
                </Row>
            </>
        );
    };
};

const DeleteUser = (props) => {
    return (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Delete user</Tooltip>}>
            <FontAwesomeIcon icon={faTrash} className={"btn-icon"} onClick={(event => {
                event.stopPropagation();

                if (window.confirm("Are you sure you want to delete the user from PCS?")) {
					userService.deleteUser(props.id).then(() => {
						props.reload(0);
					});
                }

            })}/>
        </OverlayTrigger>
    )
}

