import React from 'react';
import './style.scss';
import {NavLink} from 'react-router-dom'
import {authService} from "../../api/auth.service";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentUser: null,
            currentOrganization: props.currentOrganization
        }
    }

    componentDidMount() {
        authService.subscription.subscribe(user => {
            this.setState({currentUser: user});
        })
    }

    getItems = (currentUser) => {
        if (currentUser != null) {
            return currentUser.role === 'ADMIN' ? adminNavItems : getPartnerNavigationItems(this.props.currentOrganization);
        } else {
            return [];
        }
    };

    render() {
        const {currentUser} = this.state;
        const items = this.getItems(currentUser);

        return (
            <MenuContainer items={items} currentUser={currentUser}/>
        )
    }
}

const MenuContainer = (props) => {
    const {items} = props;

	const actonsVisible = props.currentUser != null;
	const actions = [
		{ to: "/change-password", name: "Change password", condition: actonsVisible },
		{ to: "/logout", name: "Logout", condition: actonsVisible },
	];

	const actionsMenu = () => {
		if (actonsVisible) {
			return (
                <li className={'menu-item menu-item-has-children'} style={{"float": "right"}}>
                    <NavLink to="/" isActive={() => false}><FontAwesomeIcon icon={faBars} className={"btn-icon"} /></NavLink>
					<RenderSubMenu items={actions} />
                </li>
			);
		} else {
			return (<></>);
		}
	}

    return (
        <nav style={{"width": "80%", "float": "right", "marginTop": "30px"}}>
            <ul className={'menu-container'}>
                {items.map((item, index) => {
                    return (
                        <RenderMenuItem key={index} item={item} />
                    )
                })}
				{actionsMenu()}
            </ul>
        </nav>
    )
};

const RenderMenuItem = (props) => {
    const {item} = props;

    if (item.children != null && item.condition) {
        return (
            <li className={'menu-item menu-item-has-children'}>
                <NavLink to={item.to} isActive={item.isActive}>{item.name}</NavLink>
                <RenderSubMenu items={item.children} />
            </li>
        )
    } else {
        if (item.condition) {
            return (
                <li className={"menu-item"}>
                    <NavLink to={item.to}>{item.name}</NavLink>
                </li>
            )
        } else {
            return <></>
        }
    }
};

const RenderSubMenu = (props) => {
    const {items} = props;
    return (
        <ul className={"sub-menu"}>
            {items.map((item, index) => {
                return (
                    <RenderMenuItem key={index} item={item} index={index}/>
                )
            })}
        </ul>
    )
};

function getPartnerNavigationItems(currentOrganization) {
    const isOrgAdmin = currentOrganization != null && currentOrganization['role'] === 'ORG_ADMIN';
    const userAssignedToOrg = currentOrganization != null;

    return [
        {
            to: '/certificates',
            name: 'Certificates',
            isActive: (match, location) => {
                return match || location.pathname === '/';
            },
            condition: userAssignedToOrg
        },
        {to: '/invitations', name: 'Invitations', condition: isOrgAdmin},
		{to: '/users', name: 'Users', condition: isOrgAdmin},
    ];
}

const adminNavItems = [
    {to: '/', name: 'Organizations', condition: true},
    {to: '/users', name: 'Users', condition: true},
];

export {Navigation};
