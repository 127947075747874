import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/es/Button";
import {Redirect} from 'react-router-dom'

class PasswordChanger extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isValidated: false,
            validPasswords: false,
            redirect: false
        };
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        this.setState({isValidated: true});

        if (form.checkValidity() && this.validatePasswords(form)) {
			this.props.changePassword(btoa(form.elements['password'].value)).then(() => {
				if (this.props.shouldRedirect()) {
					this.setState({redirect: true});
				}
            });
        }
    };

    validatePasswords = form => {
        const isValid = form.elements["password"].value === form.elements["repeatedPassword"].value;
        this.setState({validPasswords: isValid});
        return isValid;
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/logout'}/>
        } else {
            return (
                <Card id="activate-page">
                    <Card.Body className={"center-content"}>
                        <Card.Title className={"text-center"}>Change password!</Card.Title>
                        <Form
                            onSubmit={this.handleSubmit}
                            noValidate
                            validated={this.state.isValidated}>
                            <Form.Row>
                                <Form.Group>
                                    <Form.Control required
                                                  size="sm"
                                                  type="password"
                                                  name="password"
                                                  placeholder="New password"/>
                                    <Form.Control.Feedback type="invalid">
                                        Password is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group>
                                    <Form.Control required
                                                  size="sm"
                                                  type="password"
                                                  name="repeatedPassword"
                                                  placeholder="Repeat new password"/>
                                    <Form.Control.Feedback type="invalid">
                                        Repeat password is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <ValidatePasswordsFeedback isValid={this.state.isValidated && !this.state.validPasswords} />
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className={"margin-auto"}>
                                    <Button variant="primary" size="sm" type="submit">
                                        Go
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
            );
        }
    }
}

function ValidatePasswordsFeedback(props) {
    return (
        <Form.Control.Feedback type="invalid" className={props.isValid ? 'show' : ''}>
            Repeated password is not the same
        </Form.Control.Feedback>
    )
}

export {PasswordChanger};
