import {initErrorHandler, SpinnerWrapper} from "../utils";

const api = window._env_.API_URL;

initErrorHandler();

const find = (organization) => {
    return SpinnerWrapper.get(`${api}/organizations/${organization}/clients`);
};

const create = (organization, clients) => {
    return SpinnerWrapper.put(`${api}/organizations/${organization}/clients`, {
        client: clients
    });
};

const remove = (organization, client) => {
    return SpinnerWrapper.delete(`${api}/organizations/${organization}/clients/${client}`);
};

export const clientsService = {
    find,
    create,
    remove
};