import React from 'react';
import Card from "react-bootstrap/es/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {authService} from "../../api/auth.service";

const Login = (props) => {
    return (
        <Card id="login-page">
            <Card.Body className={"center-content"}>
                <Card.Title className={"center-text"}>Login</Card.Title>
                <Form noValidate
                      onSubmit={(event) => {handleSubmit(event, () => {props.history.push("/")})}}>
                    <Form.Row>
                        <Form.Group className={"w-100"}>
                            <Form.Control required
                                          size={"sm"}
                                          name={"username"}
                                          placeholder={"Username"}>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Form.Group className={"w-100"}>
                            <Form.Control required
                                          size={"sm"}
                                          name={"password"}
                                          type={"password"}
                                          placeholder={"Password"}>
                            </Form.Control>
                        </Form.Group>
                    </Form.Row>
                    <Form.Row>
                        <Button variant="primary" size="sm" type="submit">
                            Go
                        </Button>
                    </Form.Row>
                </Form>
            </Card.Body>
        </Card>
    );
};

const handleSubmit = (event, callback) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
        authService.login(form.elements['username'].value, form.elements['password'].value)
            .then(() => {
                callback();
            });
    }
};

export default Login;

