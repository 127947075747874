import React, {useState} from "react";
import Card from "react-bootstrap/es/Card";
import {useDropzone} from 'react-dropzone';
import Button from "react-bootstrap/es/Button";
import ButtonGroup from "react-bootstrap/es/ButtonGroup";
import {Notification, notificationStore} from "../../stores/notification.store";
import {observer} from "mobx-react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/es/Row";
import Form from "react-bootstrap/Form";
import {FormGroup} from "react-bootstrap";
import {certificateService} from "../../api/certificates.api";

const CertificatePEMUpload = (props) => {
    const onDrop = () => {
        setIsFileAttached(true);
    };
    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({noDragEventsBubbling: true, onDrop});

    const [isValidated, setIsValidated] = useState(false);
    const [isFileAttached, setIsFileAttached] = useState(false);
    const organization = props.organization;
    const certificate = props.certificate;

    return (
        <div id={"credentials-page"}>
            <Form noValidate
                  validated={isValidated}
                  onSubmit={(event) => {
                      setIsValidated(true);
                      if (acceptedFiles.length > 0) {
                          setIsFileAttached(true);
                      }

                      handleSubmit(event, organization.id, certificate.certificateId, acceptedFiles)
                  }}>
                <Row>
                    <Col sm="3" md="3" lg="3">Organization:</Col>
                    <Col><b>{organization.name}</b></Col>
                </Row>
                <Row>
                    <Col sm="3" md="3" lg="3">ClientID:</Col>
                    <Col><b>{certificate.clientId}</b></Col>
                </Row>
                <Row>
                    <Col>
                        <Card {...getRootProps({className: 'dropzone'})}>
                            <Card.Body className={"center-content"}>
                                <Card.Subtitle className={"text-center"}>Please select PEM file to upload, or drag and drop</Card.Subtitle>
                                <FormGroup>
                                    <Form.Control {...getInputProps()}
                                                  size={"sm"}
                                                  isInvalid={isValidated && !isFileAttached}
                                                  name={"certificate"}
                                                  placeholder={"Key store password"}>
                                    </Form.Control>
                                    <div className={'invalid-feedback'}>
                                        Provide <b>Certificate</b> in <u>PEM</u> format
                                    </div>
                                </FormGroup>

                                <div className={"files-container text-center"}>
                                    {acceptedFiles.map(file => {
                                        return <div key={file['path']}>{file['path']}</div>
                                    })}
                                </div>
                                <ButtonGroup className={"btn-container text-center"}>
                                    <Button variant="secondary"
                                            size="sm"
                                            className={"upload-another"}>
                                        Select file
                                    </Button>
                                </ButtonGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Button variant="primary" size="sm" type="submit">Upload certificate</Button>
                        </FormGroup>
                        <div className={"clearfix"}/>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const handleSubmit = (event, organization, certificate, acceptedFiles) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() && acceptedFiles.length === 1) {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);

        certificateService.uploadPEM(organization, certificate, formData)
            .then(() => {
                notificationStore.push(new Notification("Success", "File uploaded", "success"));
            });
    }
};

export default observer(CertificatePEMUpload);
