import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/es/Button";
import {organizationService} from "../../api/organizations.api";
import {Notification, notificationStore} from "../../stores/notification.store";

export default class CreateOrganization extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isValidated: false
        };
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isValidated: true});

        const form = event.currentTarget;
        if (form.checkValidity()) {
            organizationService.create({
                name: form.elements['name'].value,
                email: form.elements['username'].value
            }).then(() => {
                notificationStore.push(new Notification("Success", "Organization created", "error"));
            });
        }
    };

    render() {
        return (
            <Card id="create-organization-page">
                <Card.Body className={"center-content"}>
                    <Card.Title className={"text-center"}>Create organization</Card.Title>
                    <Card.Subtitle className={"text-center"}>Provide organization name and org-admin user email</Card.Subtitle>
                    <Form
                        onSubmit={this.handleSubmit}
                        noValidate
                        validated={this.state.isValidated}>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              size={"sm"}
                                              name={"name"}
                                              placeholder={"Organization name"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Provide organization name
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control
                                              type={"email"}
                                              size={"sm"}
                                              name={"username"}
                                              placeholder={"Org-admin user email"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Provide org-admin user email
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={"margin-auto"}>
                                <Button variant="primary" size="sm" type="submit">
                                    Create
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}