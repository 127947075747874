import React from "react";
import {NavLink} from 'react-router-dom'
import {Button, Col, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {organizationService} from "../../api/organizations.api";
import {userService} from "../../api/user.api";
import EditOrganizationModal from "./edit.modal";
import {OrganizationAdminView} from "../../components/Model/organization";
import Pagination from "../../components/Pagination/pagination";
import {DEFAULT_SIZE, Pageable} from "../../components/Model/pageable.class";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {Notification, notificationStore} from "../../stores/notification.store";

export default class ListOrganization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                show: false,
                organization: null
            },
            pageable: Pageable.createEmpty()
        };

        this.close = this.close.bind(this);
        this.fillOrganizations = this.fillOrganizations.bind(this);
    }

    componentDidMount() {
        this.fillOrganizations(0, DEFAULT_SIZE);
    }

    fillOrganizations(page) {
        return organizationService.find(page, DEFAULT_SIZE)
            .then(pageable => {
                this.setState({
                    pageable: pageable
                })
            });
    }

    edit(organization) {
        this.setState({
            modal: {
                show: true,
                organization: new OrganizationAdminView(organization),
            }
        });
    }

    close() {
        this.fillOrganizations(this.state.pageable.number)
            .then(() => {
                this.setState({
                    modal: {
                        show: false
                    }
                });
            })
    };

    render() {
        const pageable = this.state.pageable;
        const modal = this.state.modal;
        let popup = (<></>);

        if (modal.show) {
            popup = <EditOrganizationModal show={modal.show}
                                           organization={modal.organization}
                                           onHideCallback={() => {
                                               this.close()
                                           }}/>
        }

        return (
            <>
                <Row>
                    <Col>
                        <NavLink to={"/organizations/create"} className={"float-right"}>
                            <Button variant={"info"}>Create new Organization</Button>
                        </NavLink>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th style={{"width": 40}}>#</th>
                                <th>Name</th>
                                <th>Organization admin</th>
                                <th style={{"width": 40}}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {pageable.content.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{"width": 40}}>
                                            <b>{(index + 1) + (pageable.number) * DEFAULT_SIZE}</b>
                                        </td>
                                        <td>
                                            <a href={"#" + value['id']} onClick={(e) => {
                                                e.stopPropagation();
                                                this.edit(value);
                                            }}>
                                                <b>{value['name']}</b>
                                            </a>
                                        </td>
                                        <td style={{'width': '30%', 'fontSize': 13}}>
                                            {value['admin']}
                                        </td>
                                        <td>
                                            <SendInvitation organization={value}/>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        <Pagination className={"center"}
                                    pageable={pageable}
                                    onChangePageEvent={this.fillOrganizations}/>
                    </Col>
                </Row>
                {popup}
            </>
        );
    };
};

const SendInvitation = (props) => {
    if (!props.organization.admin) {
        return <></>;
    }

    if (!props.organization.activated) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Resend invitation</Tooltip>}>
                <FontAwesomeIcon icon={faEnvelope} className={"btn-icon"} onClick={(event => {
                    event.stopPropagation();
                    userService.invite(props.organization.adminId).then(() => {
                        notificationStore.push(new Notification("Success", "Invitation sent."));
                    });
                })}/>
            </OverlayTrigger>
        )
    } else {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Activated</Tooltip>}>
                <FontAwesomeIcon icon={faCheck} className={"btn-icon"}/>
            </OverlayTrigger>
        )
    }
};

