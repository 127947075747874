export const CertificateType = {
    QWAC: "QWAC",
    QSEAL: "QSEAL"
};

export class ClientCertificate {
    constructor(props) {
        this.clientId = props['clientId'];
        this.certificateId = props['certificateId'];
        this.hash = props['hash'];
        this.csr = props['csr'];
    }

}

Object.freeze(CertificateType);

export default {ClientCertificate, CertificateType};