
export class OrganizationAdminView {
    constructor(organization) {
        this.id = organization['id'];
        this.name = organization['name'];
        this.admin = organization['admin'];
        this.adminId = organization['adminId'];
        this.activated = organization['activated'];
    }
}

Object.freeze(OrganizationAdminView);