import React from "react";
import {Button, Col, Form, FormGroup, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {Notification, notificationStore} from "../../../stores/notification.store";
import {clientsService} from "../../../api/clients.api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faPaste} from "@fortawesome/free-solid-svg-icons";

export default class ClientsTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidated: false,
            organization: props.organization,
            clients: [],
            showCertificate: true
        }
    }

    componentDidMount() {
        this.refreshClients(this.props.organization.id);
    }

    refreshClients(organizationId) {
        clientsService.find(organizationId)
            .then(response => {
                this.setState({
                    clients: response.data
                });
            })
    };

    onSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isValidated: true
        });

        const form = event.currentTarget;
        if (form.checkValidity()) {
            const elements = form.elements;
            const client = elements['client'].value;

            clientsService.create(this.state.organization.id, client)
                .then(() => {
                    notificationStore.push(Notification.success(`Client ID: ${client} added`));
                    this.refreshClients(this.state.organization.id);
                });
        }
    };

    onRemoveClient = (event, client) => {
        event.stopPropagation();
        clientsService.remove(this.state.organization.id, client)
            .then(() => {
                this.refreshClients(this.state.organization.id);
            })
    };


    copyToClipboard = str => {
        this.setState({
            showCertificate: false
        });
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);

        this.interv = setInterval(() => {
            this.setState({
                showCertificate: true
            });
            clearInterval(this.interv);
        }, 300);
    };


    render() {
        const clients = this.state.clients;
        const organization = this.state.organization;
        return (
            <Form noValidate
                  onSubmit={this.onSubmit}
                  validated={this.state.isValidated}>
                <Row>
                    <Col>
                        <FormGroup>
                            Client id
                            <Form.Control required
                                          type={"text"}
                                          size={"sm"}
                                          name={"client"}
                                          placeholder={"Client ID"}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Provide client id
                            </Form.Control.Feedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Button variant="primary" size="sm" type="submit">
                                Add client
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive>
                            <thead>
                            <tr>
                                <th style={{'width': '50px'}}>#</th>
                                <th>Client ID</th>
                                <th>QWAC</th>
                                <th>QSEAL</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {clients.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{value.id}</td>
                                        <td>
                                            <RenderCertificate state={this.state} certificate={value.qwacHash} onClick={() => this.copyToClipboard(value.qwacHash)}/>
                                        </td>
                                        <td>
                                            <RenderCertificate state={this.state} certificate={value.qsealHash} onClick={() => this.copyToClipboard(value.qsealHash)}/>
                                        </td>
                                        <td>
                                            <a href={`#${organization.id}`} onClick={(e) => {
                                                this.onRemoveClient(e, value.id)
                                            }}>
                                                Delete
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const RenderCertificate = (props) => {
    if (props.certificate) {
        return (
             <OverlayTrigger placement={'top'} overlay={<Tooltip>{props.certificate}</Tooltip>}>
                 {props.state.showCertificate ?
                     <FontAwesomeIcon icon={faEye} className={"btn-icon"} onClick={props.onClick}/> :
                     <FontAwesomeIcon icon={faPaste} className={"btn-icon"}/>
                 }
            </OverlayTrigger>
        );
    } else {
        return (
            <FontAwesomeIcon icon={faEye} className={"btn-icon disabled"}/>
        );
    }
};

