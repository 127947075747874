import React from 'react';
import {notificationStore} from "../../stores/notification.store";
import {observer} from "mobx-react";
import Toast from 'react-bootstrap/Toast'
import {Col, Row} from "react-bootstrap";

class Notification extends React.Component {
    render() {
        return (
            <div className={"toast-container"}>
                {(notificationStore.all() || []).map((value, index) => {
                    return toast(value, index);
                })}
            </div>
        );
    }
}

function toast(value, index) {
    return (
        <Row key={index}>
            <Col>
                <Toast onClose={() => notificationStore.remove(index)}
                       show={true}
                       animation
                       autohide
                       delay={value.delay}>
                    <Toast.Header>
                        <strong className="mr-auto">{value.title}</strong>
                    </Toast.Header>
                    <Toast.Body>
                        {value.message}
                    </Toast.Body>
                </Toast>
            </Col>
        </Row>
    )
}

export default Notification = observer(Notification);