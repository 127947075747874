import React from "react";
import {Modal, Tab, Tabs} from "react-bootstrap";
import InvitationTab from "./components/invitations.tab";
import ClientsTab from "./components/clients.tab";
import UsersTab from "./components/users.tab";

export default class EditOrganizationModal extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} onHide={this.props.onHideCallback} size={"lg"}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.organization.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="invitation">
                        <Tab eventKey={"invitation"} title={"Invitations"}>
                            <InvitationTab organization={this.props.organization} />
                        </Tab>
                        <Tab eventKey={"clients"} title={"Client iDS"}>
                            <ClientsTab organization={this.props.organization} />
                        </Tab>
                        <Tab eventKey={"users"} title={"Users"}>
                            <UsersTab organization={this.props.organization} />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        )
    }
}
