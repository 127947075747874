import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Login from "../Login";
import CreateOrganization from "../Organizations/create";
import {Logout} from "./logout";
import UploadCertificate from "../Certificates/certificates-upload";
import Activation from '../Activation';
import PasswordReset from '../PasswordReset';
import ListOrganization from "../Organizations/list";
import UsersList from "../Users";
import Invitation from "../Invitation";
import UserNotAssignedToAnyOrganizationWarning from "../Warnings/UserWarnings";
import {CertificateType} from "../../components/Model/certificate";
import CertificateListPage from "../Certificates/list";
import CertificateSigningRequest from "../Certificates/certificate-signing-request";
import {NotFound} from "../error/NotFound";
import {isCSREnabled} from "../../config/environments";
import ChangePassword from "../ChangePassword";

const globalRouting = [
    {
        path: '/login',
        component: (props) => {
            return <Login {...props} />
        }
    },
    {
        path: '/logout',
        component: () => {
            return <Logout/>
        }
    },
    {
        path: '/change-password',
        component: (props) => {
            return <ChangePassword {...props}/>
        }
    },
    {
        path: '/activation/:userId/:invitationCode',
        component: (props) => {
            return <Activation {...props} />
        }
    },
    {
        path: '/password-reset/:userId/:invitationCode',
        component: (props) => {
            return <PasswordReset {...props} />
        }
    }
];

const userRouting = [
    {
        path: '/',
        component: (props) => {
            if (props.currentOrganization) {
                return <CertificateListPage organization={props.currentOrganization} />
            } else {
                return <UserNotAssignedToAnyOrganizationWarning />
            }
        }
    },
    {
        path: '/certificates',
        component: (props) => {
            return <CertificateListPage organization={props.currentOrganization} />
        }
    },
    {
        path: '/certificates/qwac/:client',
        component: (props) => {
            return <UploadCertificate certificateType={CertificateType.QWAC} currentOrganization={props.currentOrganization} {...props} />
        }
    },
    {
        path: '/certificates/qseal/:client',
        component: (props) => {
            return <UploadCertificate certificateType={CertificateType.QSEAL} currentOrganization={props.currentOrganization} {...props}  />
        }
    },
    {
        path: '/csr/:type/:client',
        condition: isCSREnabled(),
        component: (props) => {
            const {type, client} = props.match.params;
            return <CertificateSigningRequest {...props} type={type} client={client} currentOrganization={props.currentOrganization} />
        },
    },
    {
        path: '/users',
        component: () => {
            return <UsersList />
        }
    },
    {
        path: '/invitations',
        component: props => {
            return <Invitation currentOrganization={props.currentOrganization}/>
        },
        condition: (props) => {
            return props.currentOrganization != null && props.currentOrganization['role'] === 'ORG_ADMIN'
        }
    },
    {
        component: (props) => {
            return <NotFound {...props} />
        }
    }
];

const adminRouting = [
    {
        path: '/organizations/create',
        component: () => {
            return <CreateOrganization />
        }
    },
    {
        path: '/',
        component: () => {
            return <ListOrganization />
        }
    },
    {
        path: '/users',
        component: () => {
            return <UsersList />
        }
    },
    {
        component: (props) => {
            return <NotFound {...props} />
        }
    }
];

const getRouting = user => {
    if (user === null) {
        return globalRouting;
    } else {
        if (user.role === 'USER') {
            return globalRouting.concat(userRouting);
        } else if (user.role === 'ADMIN') {
            return globalRouting.concat(adminRouting);
        } else {
            return [];
        }
    }
};

export const Routing = (props) => {
    const routing = getRouting(props.currentUser);
    const conditional = (item) => {
        if (typeof item.condition === 'function') {
            return item.condition(props)
        } else if (typeof item.condition === 'boolean') {
            return item.condition;
        } else {
            return true;
        }
    };

    return (
        <Switch>
            {routing.filter(item => conditional(item)).map((item, index) => {
                return <Route path={item.path}
                              key={index}
                              exact
                              render={(route) => {
                                  return item.component(Object.assign(route, props));
                              }}/>
            })}
        </Switch>
    )
};

