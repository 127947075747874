import React from "react";
import {Notification, notificationStore} from "../../../stores/notification.store";
import {userService} from "../../../api/user.api";
import {Col, OverlayTrigger, Row, Table, Tooltip} from "react-bootstrap";
import {organizationService} from "../../../api/organizations.api";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faTrash} from "@fortawesome/free-solid-svg-icons";

export default class UsersTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: []
        };
        this.reloadUsers = this.reloadUsers.bind(this);
    }

    componentDidMount() {
        this.reloadUsers();
    }

    reloadUsers() {
        organizationService.findUsers(this.props.organization.id).then(users => {
            this.setState({ users: users.data});
        });
    }

    render() {
        const users = this.state.users;
        const organization = this.props.organization;

        return (
            <>
                <>
                    <h5>Users</h5>
                </>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive size={"sm"}>
                            <thead>
                            <tr>
                                <th>User</th>
                                <th>Role</th>
                                <th>Active?</th>
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map((value, index) => {
                                return (
                                    <tr key={index}>
                                        <td style={{"width": 40}}>
                                            <b>{value.email}</b>
                                        </td>
                                        <td style={{"width": 40}}>
                                            <b>{value.memberRole}</b>
                                        </td>
                                        <td style={{"width": 40}}>
                                            <b>{value.activated ? 'Yes' : 'No'}</b>
                                        </td>
                                        <td style={{"width": 20}}>
                                            <ResetPassword organization={organization} id={value.id} />
                                        </td>
                                        <td style={{"width": 20}}>
                                            <DeleteUserFromOrg organization={organization} email={value.email} reload={this.reloadUsers} />
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </>
        );
    };
}

const DeleteUserFromOrg = (props) => {
    return (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Delete user from organization</Tooltip>}>
            <FontAwesomeIcon icon={faTrash} className={"btn-icon"} onClick={(event => {
                event.stopPropagation();

                if (window.confirm("Are you sure you want to delete the user from the organization?")) {
                    organizationService.deleteUserFromOrganization(props.organization.id, { email: props.email }).then(() => {
                        props.reload();
                        notificationStore.push(new Notification("Success", "User has been deleted from the organization!"));
                    });
                }

            })}/>
        </OverlayTrigger>
    )
}

const ResetPassword = (props) => {

    return (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>Send password reset link</Tooltip>}>
            <FontAwesomeIcon icon={faEnvelope} className={"btn-icon"} onClick={(event => {
                event.stopPropagation();
                userService.resetPassword(props.id).then(() => {
                    notificationStore.push(new Notification("Success", "Password-reset link has been sent!"));
                });
            })}/>
        </OverlayTrigger>
    )

};
