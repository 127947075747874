import React from 'react';
import Dropdown from "react-bootstrap/es/Dropdown";

export default class OrganizationSelector extends React.Component {
    onOrganizationChange = (id) => {
        this.props.onChangeCurrentOrganization(id);
    };

    render() {
        const {currentOrganization, organizations} = this.props;
        const selectedId = currentOrganization != null ? currentOrganization['id'].toString() : null;

        if (organizations) {
            return (
                <div id="organization-selector">
                    <Dropdown onSelect={this.onOrganizationChange}>
                        <Dropdown.Toggle>
                            {
                                organizations.filter(item => {
                                    return selectedId !== null && item.id.toString() === selectedId;
                                }).map(item => {
                                    return item.name;
                                })
                            }
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {organizations.map((item, index) => {
                                return (
                                    <Dropdown.Item key={index}
                                                   eventKey={item.id}
                                                   active={selectedId === item.id.toString()}>
                                        {item.name}
                                    </Dropdown.Item>
                                )
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            )
        } else {
            return (<></>);
        }
    }
}