import React from "react";
import {Modal, OverlayTrigger, Tab, Table, Tabs, Tooltip} from "react-bootstrap";
import {Pageable} from "../../components/Model/pageable.class";
import {CertificateType} from "../../components/Model/certificate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faFileContract, faFileUpload} from '@fortawesome/free-solid-svg-icons'
import Pagination from "../../components/Pagination/pagination";
import {certificateService} from "../../api/certificates.api";
import {NavLink} from "react-router-dom";
import {isCSREnabled} from "../../config/environments";
import UploadPEMCertificate from "./certificates-pem-upload";

const DEFAULT_SIZE = 30;

export default class CertificateListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organization: props.organization,
            certificates: Pageable.createEmpty(),
            currentPage: 0,
            tab: CertificateType.QWAC,
            modal: {
                show: false,
                hash: ""
            },
            pemUpload: {
                show: false,
                type: "",
                organization: {
                    id: "",
                    name: ""
                },
                certificate: {
                    id: ""
                }
            }
        };
    }

    componentDidMount() {
        this.fillCertificates(0, this.state.tab);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.organization.id !== this.props.organization.id) {
            this.fillCertificates(0, this.state.tab);
        }
    }

    fillCertificates(page, type) {
        certificateService.find(this.props.organization.id, type, page, DEFAULT_SIZE)
            .then(value => {
                this.setState({
                    certificates: value
                })
            })
    }

    onTabChange = (tab) => {
        this.setState({
            tab: tab
        });

        this.fillCertificates(0, tab);
    };

    onPageChange = (page) => {
        this.fillCertificates(page, this.state.tab);
        this.setState({
            currentPage: page
        })
    };

    onShowHash = (title, content) => {
        this.setState({
            modal: {
                show: true,
                content: content,
                title: title
            }
        })
    };

    onCloseHash = () => {
        this.setState({
            modal: {
                show: false,
                content: "",
                title: ""
            }
        })
    };

    onPemUploadOpen = (type, organization, certificate) => {
        this.setState({
            pemUpload: {
                show: true,
                type: type,
                organization: organization,
                certificate: certificate
            }
        })
    };

    onPemUploadClose = () => {
        this.setState({
            pemUpload: {
                show: false,
                type: "",
                organization: {
                    id: "",
                    name: ""
                },
                certificate: {
                    id: ""
                }
            }
        });

        this.onPageChange(this.state.currentPage);
    };

    render() {
        const {tab, certificates, modal} = this.state;

        return (
            <div>
                <Tabs onSelect={this.onTabChange} activeKey={tab}>
                    <Tab eventKey={CertificateType.QWAC} title={CertificateType.QWAC}>
                        <TableWithCertificates certificates={certificates}
                                               type={CertificateType.QWAC}
                                               organization={this.props.organization}
                                               onPageChange={this.onPageChange}
                                               onClick={this.onShowHash}
                                               onPemUploadOpen={this.onPemUploadOpen}/>
                    </Tab>
                    <Tab eventKey={CertificateType.QSEAL} title={CertificateType.QSEAL}>
                        <TableWithCertificates certificates={certificates}
                                               type={CertificateType.QSEAL}
                                               organization={this.props.organization}
                                               onPageChange={this.onPageChange}
                                               onClick={this.onShowHash}
                                               onPemUploadOpen={this.onPemUploadOpen}/>
                    </Tab>
                </Tabs>

                <Modal show={modal.show} onHide={this.onCloseHash}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modal.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className={"code"}>
                            {modal.content}
                        </div>
                    </Modal.Body>
                </Modal>


                <Modal show={this.state.pemUpload.show} onHide={this.onPemUploadClose}>
                    <Modal.Header closeButton>

                        <Modal.Title>Upload {this.state.pemUpload.type} Certificate</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <UploadPEMCertificate organization={this.state.pemUpload.organization} certificate={this.state.pemUpload.certificate}/>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const TableWithCertificates = (props) => {
    const {certificates, onPageChange, onClick} = props;
    return (
        <div>
            <Table striped bordered hover responsive size={"sm"}>
                <thead>
                <tr>
                    <th style={{'width': 40}}>#</th>
                    <th>Client</th>
                    <th>Certificate</th>
                    <th>PKCS12</th>
                    {isCSREnabled() ? <th>CSR</th> : ''}
                </tr>
                </thead>
                <tbody>
                {certificates.content.map((certificate, index) => {
                    return (
                        <tr key={index}>
                            <td>{(index + 1) + (certificates.number) * DEFAULT_SIZE}</td>
                            <td>{certificate.clientId}</td>
                            <td>
                                <ShowCertificate certificate={certificate} onClick={onClick}/>
                            </td>
                            <td>
                                <UploadCertificate certificate={certificate} type={props.type}/>
                            </td>
                            <CSRActions certificate={certificate} organization={props.organization}
                                        onClick={onClick} type={props.type} onPemUploadOpen={props.onPemUploadOpen}/>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
            <Pagination className={"center"} pageable={certificates} onChangePageEvent={onPageChange}/>
        </div>
    )
};

const ShowCertificate = (props) => {
    if (props.certificate.hash) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>View certificate hash</Tooltip>}>
                <a href={`#${props.certificate.certificateId}`} onClick={() => {
                    props.onClick("Certificate Hash", props.certificate.hash);
                }}>
                    <FontAwesomeIcon icon={faEye} className={"btn-icon"}/>
                </a>
            </OverlayTrigger>
        )
    } else {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>View certificate hash</Tooltip>}>
                <FontAwesomeIcon icon={faEye} className={"btn-icon disabled"}/>
            </OverlayTrigger>
        );
    }
};

const UploadCertificate = (props) => {
    return (
        <OverlayTrigger placement={'top'} overlay={<Tooltip>{`Upload ${props.type} certificate`}</Tooltip>}>
            <NavLink to={`/certificates/${props.type}/${props.certificate.clientId}`}>
                <FontAwesomeIcon icon={faFileUpload} className={"btn-icon"}/>
            </NavLink>
        </OverlayTrigger>
    )
};

const CSRActions = (props) => {
    if (!isCSREnabled()) {
        return '';
    }

    return (
        <td>
            <GenerateCSR certificate={props.certificate} type={props.type}/>
            <DisplayCSR certificate={props.certificate} type={props.type} onClick={props.onClick}/>
            <UploadPEM certificate={props.certificate} type={props.type} organization={props.organization} onPemUploadOpen={props.onPemUploadOpen}/>
        </td>
    )
};

const GenerateCSR = (props) => {
    if (!props.certificate.hash) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Generate CSR</Tooltip>}>
                <NavLink to={`/csr/${props.type}/${props.certificate.clientId}`}>
                    <FontAwesomeIcon icon={faFileContract} className={"btn-icon"}/>
                </NavLink>
            </OverlayTrigger>
        );
    } else {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Generate CSR</Tooltip>}>
                <FontAwesomeIcon icon={faFileContract} className={"btn-icon disabled"}/>
            </OverlayTrigger>
        );
    }
};

const DisplayCSR = (props) => {
    if (props.certificate.csr) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>View CSR</Tooltip>}>
                <a href={`#${props.certificate.certificateId}`} onClick={() => {
                    props.onClick("Certificate Sign Request", props.certificate.csr);
                }}>
                    <FontAwesomeIcon icon={faEye} className={"btn-icon"}/>
                </a>
            </OverlayTrigger>
        );
    } else {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>View CSR</Tooltip>}>
                <FontAwesomeIcon icon={faEye} className={"btn-icon disabled"}/>
            </OverlayTrigger>
        );
    }
};

const UploadPEM = (props) => {
    if (props.certificate.csr) {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Upload certificate</Tooltip>}>
                <FontAwesomeIcon icon={faFileUpload} className={"btn-icon"} onClick={
                    () => props.onPemUploadOpen(props.type, props.organization, props.certificate)
            }/>
            </OverlayTrigger>
        );
    } else {
        return (
            <OverlayTrigger placement={'top'} overlay={<Tooltip>Upload certificate</Tooltip>}>
                <FontAwesomeIcon icon={faFileUpload} className={"btn-icon disabled"}/>
            </OverlayTrigger>
        );
    }
};