import React, {useEffect, useState} from "react";
import Card from "react-bootstrap/es/Card";
import {useDropzone} from 'react-dropzone';
import Button from "react-bootstrap/es/Button";
import ButtonGroup from "react-bootstrap/es/ButtonGroup";
import {Notification, notificationStore} from "../../stores/notification.store";
import {observer} from "mobx-react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/es/Row";
import Form from "react-bootstrap/Form";
import {Alert, FormGroup} from "react-bootstrap";
import {certificateService} from "../../api/certificates.api";

const UploadCertificate = (props) => {
    const onDrop = () => { setIsFileAttached(true); };
    const {
        acceptedFiles,
        getRootProps,
        getInputProps
    } = useDropzone({noDragEventsBubbling: true, onDrop});

    const client = props.match.params['client'];
    const [keyStorePassword, setKeyStorePassword] = useState("");
    const [isValidated, setIsValidated] = useState(false);
    const currentOrganization = props.currentOrganization;
    const certificateType = props.certificateType;
    const organizationId = currentOrganization !== null ? currentOrganization.id : null;
    const organizationName = currentOrganization !== null ? currentOrganization.name : "Organization";
    const [isFileAttached, setIsFileAttached] = useState(false);

    useEffect(() => {
        setKeyStorePassword("");
    }, [currentOrganization]);

    return (
        <div id={"credentials-page"}>
            <Form noValidate
                  validated={isValidated}
                  onSubmit={(event) => {
                      setIsValidated(true);
                      if (acceptedFiles.length > 0) {
                          setIsFileAttached(true);
                      }

                      handleSubmit(event, certificateType, organizationId, client, acceptedFiles)}
                  }>
                <Row>
                    <Col>
                        <Alert variant={"info"}>
                            Your are uploading <b>{certificateType}</b> certificate for client <b>{client}</b> for organization: <b>{organizationName}</b>
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card {...getRootProps({className: 'dropzone'})}>
                            <Card.Body className={"center-content"}>
                                <Card.Subtitle className={"text-center"}>Please select file to upload, or drag and drop</Card.Subtitle>

                                <FormGroup>
                                    <Form.Control {...getInputProps()}
                                                  size={"sm"}
                                                  isInvalid={isValidated && !isFileAttached}
                                                  name={"certificate"}
                                                  placeholder={"Key store password"}>
                                    </Form.Control>
                                    <div className={'invalid-feedback'}>
                                        Provide <b>Certificate</b> in <u>pkcs12</u> format
                                    </div>
                                </FormGroup>

                                <div className={"files-container text-center"}>
                                    {acceptedFiles.map(file => {
                                        return <div key={file['path']}>{file['path']}</div>
                                    })}
                                </div>
                                <ButtonGroup className={"btn-container text-center"}>
                                    <Button variant="secondary"
                                            size="sm"
                                            className={"upload-another"}>
                                        Upload another
                                    </Button>
                                </ButtonGroup>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <FormGroup className={"d-none"}>
                            <Form.Control required
                                          type={"text"}
                                          name={"organization"}
                                          placeholder={"Organization"}
                                          size={"sm"}
                                          disabled={true}
                                          value={organizationName}>
                            </Form.Control>
                        </FormGroup>
                        <FormGroup>
                            <Form.Control required
                                          type={"password"}
                                          value={keyStorePassword}
                                          onChange={event => {
                                              setKeyStorePassword(event.target.value)
                                          }}
                                          size={"sm"}
                                          name={"password"}
                                          placeholder={"Key store password"}>
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                Provide <b>Key store password</b>
                            </Form.Control.Feedback>
                        </FormGroup>
                        <FormGroup>
                            <Button variant="primary"
                                    size="sm"
                                    type="submit"
                                    className={"finish"}>
                                Finish
                            </Button>
                        </FormGroup>
                        <div className={"clearfix"} />
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

const handleSubmit = (event, certificateType, organization, client, acceptedFiles) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity() && acceptedFiles.length === 1) {
        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('password', btoa(form.elements['password'].value));
        formData.append('type', certificateType);
        formData.append("clientId", client);

        certificateService.upload(organization, formData)
            .then(() => {
                notificationStore.push(new Notification("Success", "File uploaded", "success"));
            });
    }
};

export default observer(UploadCertificate);
