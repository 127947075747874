import React from "react";
import {Button, Col, Form, FormGroup, Row} from "react-bootstrap";
import {Notification, notificationStore} from "../../../stores/notification.store";
import {MemberRole} from "../../../components/Model/member";
import {membersService} from "../../../api/members.api";

function getAvailableRoles(organization) {
    return organization.admin ? [MemberRole.ORG_USER] : [MemberRole.ORG_USER, MemberRole.ORG_ADMIN];
}

export default class InvitationTab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidated: false
        };

        this.onInvitationSubmit.bind(this);
    }

    onInvitationSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({
            isValidated: true
        });

        const form = event.currentTarget;
        if (form.checkValidity()) {
            membersService.create(this.props.organization['id'], {
                email: form.elements['username'].value,
                role: form.elements['role'].value
            }).then(() => {
                notificationStore.push(Notification.success("User added to organization"))
            });
        }
    };

    render() {
        const organization = this.props.organization;
        return (
            <>
                <h5>Invite new users</h5>
                <Form noValidate
                      onSubmit={this.onInvitationSubmit}
                      validated={this.state.isValidated}>
                    <Row>
                        <Col sm={4}>
                            <FormGroup>
                                Role
                                <Form.Control as="select"
                                              size={"sm"}
                                              name={"role"}
                                              required
                                              placeholder={"Select role"}>
                                    {getAvailableRoles(organization).map((value, index) => {
                                        return <option key={index} value={value}>
                                            {value}
                                        </option>
                                    })}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Provide user role
                                </Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                        <Col sm={8}>
                            <FormGroup>
                                E-mail address
                                <Form.Control required
                                              type={"email"}
                                              size={"sm"}
                                              name={"username"}
                                              placeholder={"User e-mail address*"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Provide user email
                                </Form.Control.Feedback>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Button variant="primary" size="sm" type="submit">
                                    Send invitation
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}
