import React, {useState} from "react";
import {observer} from "mobx-react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/es/Button";
import {Notification, notificationStore} from "../../stores/notification.store";
import Col from "react-bootstrap/Col";
import './style.scss';
import {Alert, Modal} from "react-bootstrap";
import {certificateService} from "../../api/certificates.api";
import Row from "react-bootstrap/es/Row";
import {CertificateType} from "../../components/Model/certificate";

const maxSanValues = 18;
const dnsPattern = "^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$";
const defaultPattern = "(.*)";
const csrFileName = "generated.csr";

const CertificateSigningRequest = (props) => {
    const organization = props.currentOrganization;
    const client = props.client;
    const type = props.type;

    const [isValidated, setIsValidated] = useState(false);
    const commonNamePattern = type === CertificateType.QSEAL ? dnsPattern : defaultPattern;
    const [san, setSan] = useState("");
    const [sans, setSans] = useState([]);
    const [show, setShow] = useState(false);
    const [csrContent, setCsrContent] = useState("");
    const handleClose = () => setShow(false);
    const organizationId = organization !== null ? organization.id : null;
    const handleOnAddSan = () => {
        if (sans.length < maxSanValues) {
            sans.push(san);
            setSan("");
        } else {
            notificationStore.push(new Notification("Max 'SAN' values", "There is max values", "error", 3000));
        }
    };

    const handleOnSaveToFile = () => {
        const element = document.createElement("a");
        let file = new Blob([csrContent], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download = csrFileName;
        document.body.appendChild(element);
        element.click();
    };

    return (
        <>
            <Row>
                <Col>
                    <Alert variant={"info"}>
                        Your are uploading <b>{type}</b> certificate for client <b>{client}</b> for organization: <b>{organization.name}</b>
                    </Alert>
                </Col>
            </Row>
            <Card id="certificate-signing-request">
                <Card.Body className={"center-content"} >
                    <Card.Title className={"text-center"}>Certificate Signing Request</Card.Title>
                    <Form noValidate
                          onSubmit={(event) => {
                              setIsValidated(true);
                              handleSubmit(event, organizationId, client, type, sans, res => {
                                  setShow(true);
                                  setCsrContent(res);
                              });
                          }}
                          validated={isValidated}>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              type={"text"}
                                              size={"sm"}
                                              name={"organization"}
                                              placeholder={"Organization (O)"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <b>Organization</b> is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              pattern={commonNamePattern}
                                              type={"text"}
                                              size={"sm"}
                                              name={"commonName"}
                                              placeholder={"Common Name (CN i.e DNS name)"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <b>Common name</b> is required <br />
                                    If you are using <b>QSEAL</b> certificate type then only allowed <b>DNS</b> format
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              type={"text"}
                                              size={"sm"}
                                              maxLength={2}
                                              minLength={2}
                                              name={"countryCode"}
                                              placeholder={"Country Code (C)"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <b>Country Code</b> is required <br />
                                    Provider country code with <b>ISO 3166-1 alpha-2</b> format
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              type={"text"}
                                              size={"sm"}
                                              name={"state"}
                                              placeholder={"State or Province (S)"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <b>State or province</b> is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              type={"text"}
                                              size={"sm"}
                                              name={"city"}
                                              placeholder={"City (L)"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    <b>City</b> is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control type={"text"}
                                              size={"sm"}
                                              name={"unit"}
                                              placeholder={"Organizational Unit (OU)"}>
                                </Form.Control>
                            </Form.Group>
                        </Form.Row>
                            <Form.Row>
                                <Col xs={9} style={{'paddingLeft':0}}>
                                    <Form.Group>
                                        <Form.Control type={"text"}
                                                      size={"sm"}
                                                      value={san}
                                                      onChange={(event) => {
                                                          setSan(event.target.value);
                                                      }}
                                                      name={"subject"}
                                                      placeholder={"Subject alternate name (SAN)"}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            <b>Subject alternate name</b> can contains only <b>18</b> characters
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs={3} style={{'paddingRight': 0}}>
                                    <Form.Group>
                                        <Button variant="success" size="sm" className={"addSAN"} onClick={handleOnAddSan}>
                                            Add
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                                {sans.map((value, index) => {
                                    return (
                                        <Form.Row key={index}>
                                            <Col xs={9} style={{'paddingLeft':0}}>
                                                <Form.Group>
                                                    <Form.Control type={"text"}
                                                                  readOnly
                                                                  size={"sm"}
                                                                  defaultValue={value}
                                                                  name={`csr-wizard-san`}
                                                                  placeholder={"Subject alternate name (SAN)"}>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col xs={3} style={{'paddingRight': 0}}>
                                                <Form.Group>
                                                    <Button variant="danger" size="sm" onClick={() => {
                                                        setSans(sans.slice(0, index).concat(sans.slice(index + 1, sans.length)));
                                                    }}>
                                                        Remove
                                                    </Button>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    )
                                })}
                            <Form.Row>
                                <Form.Group className={"margin-auto"}>
                                    <Button variant="primary" size="sm" type="submit">
                                        Create
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Certificate Sign Request</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className={"code"}>
                        {csrContent}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleOnSaveToFile}>
                        Save to file
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};

const handleSubmit = (event, organization, client, type, sans, callback) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    if (form.checkValidity()) {
        const payload = {
            clientId: client,
            type: type,
            organization: form.elements['organization'].value,
            commonName: form.elements['commonName'].value,
            country: form.elements['countryCode'].value,
            state: form.elements['state'].value,
            city: form.elements['city'].value,
            organizationalUnit: form.elements['unit'].value,
            san: sans
        };

        certificateService.createCSR(organization, payload)
            .then((res) => {
                callback(res.data);
            })
            .catch(reject => {
                notificationStore.push(new Notification("Error", reject.message, "error"));
            });
    }
};

export default observer(CertificateSigningRequest);
