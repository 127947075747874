import {PageableSpinner, SpinnerWrapper} from "../utils";

const apiUrl = window._env_.API_URL;

const activate = (userId, invitationCode, payload) => {
    return SpinnerWrapper.patch(`${apiUrl}/users/${userId}/invitations/${invitationCode}/activate`, payload);
};

const invite = (id) => {
    return SpinnerWrapper.put(`${apiUrl}/users/${id}/invite`);
};

const resetPassword = (id) => {
  return SpinnerWrapper.post(`${apiUrl}/users/${id}/reset_password`);
}

const resetPasswordConfirm = (id, invitationCode, payload) => {
  return SpinnerWrapper.patch(`${apiUrl}/users/${id}/invitations/${invitationCode}/reset_password`, payload);
}

const getUsers = (page, size) => {
	return PageableSpinner.get(`${apiUrl}/users`, page, size);
}

const deleteUser = (id) => {
	return SpinnerWrapper.delete(`${apiUrl}/users/${id}`);
}

const changePassword = (payload) => {
    return SpinnerWrapper.put(`${apiUrl}/users/change_password`, payload);
}

export const userService = {activate, invite, resetPassword, resetPasswordConfirm, getUsers, deleteUser, changePassword}
