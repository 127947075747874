import {initErrorHandler, PageableSpinner, SpinnerWrapper} from "../utils";

const api = window._env_.API_URL;

initErrorHandler();

const getUserOrganizations = async () => {
    return SpinnerWrapper.get(`${api}/users/organizations`)
};

const create = (payload) => {
    return SpinnerWrapper.post(`${api}/organizations/`, payload);
};

const find = (page, size) => {
    return PageableSpinner.get(`${api}/organizations`, page, size)
};

const findUsers = (id) => {
    return SpinnerWrapper.get(`${api}/organizations/${id}/users`)
}

const deleteUserFromOrganization = (id, payload) => {
    return SpinnerWrapper.delete(`${api}/organizations/${id}/users`, payload)
}

export const organizationService = {
    getUserOrganizations,
    create,
    find,
    findUsers,
    deleteUserFromOrganization
};
