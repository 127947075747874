export class Member {
    constructor(props) {
        this.role = props['role'];
    }
}

const MemberRole = {
    'ORG_ADMIN': 'ORG_ADMIN',
    "ORG_USER": 'ORG_USER'
};

export {MemberRole};