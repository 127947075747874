import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/es/Button";
import {Notification, notificationStore} from "../../stores/notification.store";
import {MemberRole} from "../../components/Model/member";
import {membersService} from "../../api/members.api";

export default class Invitation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValidated: false
        };
    }

    handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({isValidated: true});

        const form = event.currentTarget;
        if (form.checkValidity()) {
            membersService.create(this.props.currentOrganization.id, {
                email: form.elements['username'].value,
                role: MemberRole.ORG_USER
            }).then(() => {
                notificationStore.push(Notification.success("User added to organization"));
            });
        }
    };

    render() {
        const {currentOrganization} = this.props;

        return (
            <Card id="invitation-page">
                <Card.Body className={"center-content"}>
                    <Card.Title className={"text-center"}>Invite new users</Card.Title>
                    <Card.Subtitle className={"text-center"}>
                        Invite new users to your organization:
                        <b>{currentOrganization.name}</b>
                    </Card.Subtitle>
                    <Form
                        onSubmit={this.handleSubmit}
                        noValidate
                        validated={this.state.isValidated}>
                        <Form.Row>
                            <Form.Group>
                                <Form.Control required
                                              type={"email"}
                                              size={"sm"}
                                              name={"username"}
                                              placeholder={"Org-user email"}>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Provide org-user email
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group className={"margin-auto"}>
                                <Button variant="primary" size="sm" type="submit">
                                    Create
                                </Button>
                            </Form.Group>
                        </Form.Row>
                    </Form>
                </Card.Body>
            </Card>
        );
    }
}