import React from 'react';
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/es/Button";
import Col from "react-bootstrap/Col";
import {observer} from "mobx-react";
import {Notification, notificationStore} from "../../stores/notification.store";
import {Redirect} from 'react-router-dom'
import {userService} from "../../api/user.api";

class Activation extends React.Component {
    constructor(props) {
        super(props);

        this.userId = props.match.params.userId;
        this.invitationCode = props.match.params.invitationCode;
        this.state = {
            isValidated: false,
            validPasswords: false,
            redirect: false
        };
    }

    handleSubmit = event => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;
        this.setState({isValidated: true});

        if (form.checkValidity() && this.validatePasswords(form)) {
            userService.activate(this.userId, this.invitationCode, {
                password: btoa(form.elements['password'].value),
                first_name: form.elements['firstName'].value,
                last_name: form.elements['lastName'].value,
            }).then(() => {
                notificationStore.push(Notification.success("User is activated now"));
                this.setState({redirect: true});
            });
        }
    };

    validatePasswords = form => {
        const isValid = form.elements["password"].value === form.elements["repeatedPassword"].value;
        this.setState({validPasswords: isValid});
        return isValid;
    };

    render() {
        if (this.state.redirect) {
            return <Redirect to={'/login'}/>
        } else {
            return (
                <Card id="activate-page">
                    <Card.Body className={"center-content"}>
                        <Card.Title className={"text-center"}>Welcome user</Card.Title>
                        <Card.Subtitle className={"text-center"}>Please set up a password</Card.Subtitle>
                        <Form
                            onSubmit={this.handleSubmit}
                            noValidate
                            validated={this.state.isValidated}>
                            <Form.Row className={"person"}>
                                <Col>
                                    <Form.Group>
                                        <Form.Control required
                                                      size={"sm"}
                                                      name={"firstName"}
                                                      placeholder={"First name"}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Provide first name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Control required
                                                      size={"sm"}
                                                      name={"lastName"}
                                                      placeholder={"Last name"}>
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            Provide last name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group>
                                    <Form.Control required
                                                  size="sm"
                                                  type="password"
                                                  name="password"
                                                  placeholder="Password"/>
                                    <Form.Control.Feedback type="invalid">
                                        Password is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group>
                                    <Form.Control required
                                                  size="sm"
                                                  type="password"
                                                  name="repeatedPassword"
                                                  placeholder="Repeat Password"/>
                                    <Form.Control.Feedback type="invalid">
                                        Repeat password is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <ValidatePasswordsFeedback isValid={this.state.isValidated && !this.state.validPasswords} />
                            </Form.Row>
                            <Form.Row>
                                <Form.Group className={"margin-auto"}>
                                    <Button variant="primary" size="sm" type="submit">
                                        Go
                                    </Button>
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
            );
        }
    }
}

function ValidatePasswordsFeedback(props) {
    return (
        <Form.Control.Feedback type="invalid" className={props.isValid ? 'show' : ''}>
            Repeated password is not the same
        </Form.Control.Feedback>
    )
}

export default Activation = observer(Activation);