import {BehaviorSubject} from "rxjs";
import {spinnerWrapper, SpinnerWrapper} from "../utils";
import axios from "axios";
import User from "../components/Model/user";
import {Notification, notificationStore} from "../stores/notification.store";

const api = window._env_.API_URL;

const getUser = () => {
    const user = localStorage.getItem('user');
    return user !== null ? JSON.parse(user) : null;
};

const subscription = new BehaviorSubject(getUser());

const save = (user) => {
    localStorage.setItem('user', JSON.stringify(user));
    subscription.next(user);
};

const login = (username, password) => {
    const config = {
        headers: {
            'Authorization': 'Basic ' + btoa(username + ":" + password)
        }
    };
    return spinnerWrapper(axios.get(`${api}/users/details`, config))
        .then(response => {
            save(new User(response.data, username, password));
            return response;
        }).catch(reason => {
            notificationStore.push(Notification.error("Wrong username or password"));
            return Promise.reject(reason);
        })
};

const logout = () => {
    return SpinnerWrapper.post(`${api}/logout`)
        .finally(() => {
            localStorage.removeItem("user");
            subscription.next(null);
        });
};

const getAuthHeaders = () => {
    const user = getUser();
    return !!user ? {'Authorization': 'Basic ' + user.auth} : {};
};

export const authService = {
    login,
    logout,
    getAuthHeaders,
    getUser,
    subscription
};