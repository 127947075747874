import {initErrorHandler, SpinnerWrapper} from "../utils";

const api = window._env_.API_URL;

initErrorHandler();

const create = (organization, payload) => {
    return SpinnerWrapper.put(`${api}/organizations/${organization}/users`, payload);
};

export const membersService = {
    create
};