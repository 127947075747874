import React from "react";
import {Pagination as BootstrapPagination} from "react-bootstrap";

export default class Pagination extends React.Component {
    pageable;

    page(e, index) {
        e.preventDefault();
        e.stopPropagation();
        this.props.onChangePageEvent(index);
        this.setState({
            number: index
        })
    }

    renderPages(pageable) {
        const pages = [];
        for (let i=0; i<pageable.totalPages; i++) {
            pages.push(
                <BootstrapPagination.Item active={pageable.number === i} key={i} onClick={(e) => {
                    if (pageable.number !== i) {
                        this.page(e, i);
                    }
                }}>{i+1}
                </BootstrapPagination.Item>);
        }

        return pages;
    }

    render() {
        const pageable = this.props.pageable;
        const pages = this.renderPages(pageable);

        return (
            <BootstrapPagination onClick={this.onChange}>
                <BootstrapPagination.First disabled={pageable.empty || pageable.first} onClick={(e) => {
                    this.page(e, 0)
                }}/>
                <BootstrapPagination.Prev disabled={pageable.empty || pageable.first} onClick={(e) => {
                    this.page(e, pageable.number <= 0 ? 0 : pageable.number-1);
                }}/>

                {pages.map((item) => {
                    return item;
                })}

                <BootstrapPagination.Next disabled={pageable.empty || pageable.last} onClick={(e) => {
                    this.page(e, pageable.number < pageable.totalPages - 1 ? pageable.number + 1 : pageable.totalPages-1);
                }}/>
                <BootstrapPagination.Last disabled={pageable.empty || pageable.last} onClick={(e) => {
                    this.page(e, pageable.totalPages-1);
                }}/>
            </BootstrapPagination>
        );
    }
}