import {decorate, observable} from 'mobx';

class NotificationStore {
    constructor() {
        this.notifications = [];
    };

    all = () => {
        return this.notifications;
    };

    push = notification => {
        this.notifications.push(notification);
    };

    remove = index => {
        this.notifications = this.notifications.filter((item, i) => {
            return i !== index;
        });
    }
}

decorate(NotificationStore, {
    notifications: observable
});

const NotificationType = {
    ERROR: "ERROR",
    SUCCESS: "SUCCESS"
};

export class Notification {
    constructor(title, message, type, delay) {
        this.title = title;
        this.message = message;
        this.type = type;
        this.delay = delay || 3000;
    }

    static success(message, title, delay) {
        return new Notification((title ? title : "Success"), message, NotificationType.SUCCESS, delay ? delay : 3000);
    }

    static error(message, title, delay) {
        return new Notification((title ? title : 'Error'), message, NotificationType.ERROR, delay ? delay : 3000);
    }
}

export const notificationStore = new NotificationStore();