import React from 'react';
import {NavLink} from "react-router-dom";

export const Logo = () => {
    return (
        <NavLink to="/" className={"logo"}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 230.9 74.78">
                <title>Artboard 56</title>
                <g id="Layer_1" data-name="Layer 1">
                    <path className="cls-1"
                          d="M42.05,74.78A35.33,35.33,0,1,0,6.72,39.45,35.33,35.33,0,0,0,42.05,74.78"/>
                    <path className="cls-2" d="M12,24A12,12,0,1,0,0,12,12,12,0,0,0,12,24"/>
                    <path className="cls-1"
                          d="M94.79,62.53A13,13,0,0,0,108,49.41a13.22,13.22,0,0,0-22.49-9.32,13.24,13.24,0,0,0,0,18.65,12.71,12.71,0,0,0,9.32,3.79m-5.53-7.48a8.21,8.21,0,0,1,0-11.27,8,8,0,0,1,11.07,0,8.21,8.21,0,0,1,0,11.27,8,8,0,0,1-11.07,0M123.54,36.3c-3.54,0-6.08,1.3-7.73,3.84V37h-5.38V61.88h5.38V48.52c0-4.84,2.54-7.18,6.33-7.18,3.44,0,5.59,2,5.59,5.78V61.88h5.38V46.57c0-6.38-3.84-10.27-9.57-10.27m26.05,0c-3.54,0-6.08,1.3-7.72,3.84V37h-5.39V61.88h5.39V48.52c0-4.84,2.54-7.18,6.33-7.18,3.44,0,5.58,2,5.58,5.78V61.88h5.39V46.57c0-6.38-3.84-10.27-9.58-10.27m37.74,15.41a14.66,14.66,0,0,0,.2-2.25A13.35,13.35,0,0,0,184,40.14a11.72,11.72,0,0,0-9-3.84,12.58,12.58,0,0,0-9.47,3.79,12.74,12.74,0,0,0-3.69,9.32,12.77,12.77,0,0,0,3.74,9.43,13.47,13.47,0,0,0,9.82,3.69c4.84,0,8.48-1.8,10.92-5.44l-4.44-2.59a7.39,7.39,0,0,1-6.43,3.09c-4.14,0-7.18-2.09-8-5.88Zm-19.9-4.39A7.24,7.24,0,0,1,175,41.19c3.54,0,6.38,2.09,7.13,6.13Zm35.45,15.21A12.55,12.55,0,0,0,214.1,56l-4.64-2.64a7.26,7.26,0,0,1-6.63,3.84A7.53,7.53,0,0,1,197.3,55a8.19,8.19,0,0,1,0-11.17,7.49,7.49,0,0,1,5.53-2.24,6.92,6.92,0,0,1,6.53,3.84l4.64-2.7a12.28,12.28,0,0,0-11.12-6.43,12.78,12.78,0,0,0-9.42,3.79,12.59,12.59,0,0,0-3.74,9.32,12.86,12.86,0,0,0,13.16,13.12M230.9,37h-6.18V30l-5.39,1.59V37h-4.58v5.18h4.58v12c0,6.53,3.34,8.78,11.57,7.78V57c-4.09.2-6.18.25-6.18-2.94v-12h6.18V37Z"/>
                    <path className="cls-3"
                          d="M44.14,61.67a21.28,21.28,0,0,0,19-11l-7.86-4.48a12.34,12.34,0,0,1-11.24,6.51,12.77,12.77,0,0,1-9.38-3.8A13.07,13.07,0,0,1,31,39.45,13.07,13.07,0,0,1,34.67,30a12.77,12.77,0,0,1,9.38-3.8c5.07,0,9.13,2.45,11.07,6.5L63,28.13c-3.38-6.67-10.65-10.9-18.84-10.9a21.66,21.66,0,0,0-16,6.43,21.36,21.36,0,0,0-6.34,15.79A21.8,21.8,0,0,0,44.14,61.67"/>
                    <path className="cls-2"
                          d="M90.21,13.42v9.44h-2V15.38H85.08v7.48h-2V15.38H81.63v-2h1.43v-.18c0-2.55,1.4-3.84,4.23-3.83a9.11,9.11,0,0,1,2.08.24v1.87a15.18,15.18,0,0,0-2.08-.15c-1.5,0-2.21.49-2.21,1.87v.19Z"/>
                    <path className="cls-2"
                          d="M100.71,17.07v5.8h-2V17.28a2,2,0,0,0-2.1-2.2c-1.42,0-2.38.89-2.38,2.72v5.06h-2V13.42h2v1.21a3.18,3.18,0,0,1,2.9-1.45c2.16,0,3.6,1.47,3.6,3.89"/>
                    <polygon className="cls-2"
                             points="102.5 22.87 102.51 9.09 104.53 9.09 104.53 22.87 102.5 22.87 102.5 22.87"/>
                    <path className="cls-2"
                          d="M108.09,19a3.11,3.11,0,0,0,5.41,1.06l1.67,1a4.61,4.61,0,0,1-4.11,2.06,5.08,5.08,0,0,1-3.69-1.4,4.9,4.9,0,0,1-1.4-3.57,4.84,4.84,0,0,1,1.39-3.53,4.72,4.72,0,0,1,3.56-1.43,4.37,4.37,0,0,1,3.37,1.45A5.19,5.19,0,0,1,115.54,19Zm5.49-1.66A2.61,2.61,0,0,0,110.9,15a2.72,2.72,0,0,0-2.83,2.32Z"/>
                    <path className="cls-2"
                          d="M124.39,13.44h2v9.44h-2V21.52a3.78,3.78,0,0,1-3.28,1.61,4.42,4.42,0,0,1-3.3-1.44,4.94,4.94,0,0,1-1.37-3.53,5,5,0,0,1,1.37-3.53,4.36,4.36,0,0,1,3.3-1.44,3.8,3.8,0,0,1,3.28,1.59V13.44Zm-3,7.74a2.84,2.84,0,0,0,2.11-.85,3,3,0,0,0,.85-2.17,2.93,2.93,0,0,0-.84-2.15,3,3,0,0,0-4.24,0,2.92,2.92,0,0,0-.84,2.15,3,3,0,0,0,.84,2.17,2.85,2.85,0,0,0,2.12.85"/>
                    <path className="cls-2"
                          d="M137,14.66a4.88,4.88,0,0,1,1.37,3.51A4.89,4.89,0,0,1,137,21.7a4.39,4.39,0,0,1-3.3,1.43,3.8,3.8,0,0,1-3.28-1.59v5.12h-2V13.44h2v1.37a3.78,3.78,0,0,1,3.29-1.61A4.34,4.34,0,0,1,137,14.66m-3.62,6.53a2.86,2.86,0,0,0,2.12-.85,3,3,0,0,0,.84-2.17A2.92,2.92,0,0,0,135.5,16a3,3,0,0,0-4.23,0,3,3,0,0,0-.85,2.16,3,3,0,0,0,.84,2.17,2.92,2.92,0,0,0,2.12.85"/>
                </g>
            </svg>
        </NavLink>
    );
};