import {authService} from "./api/auth.service";
import axios from 'axios';
import {spinnerService} from "./components/Loader";
import {Pageable} from "./components/Model/pageable.class";
import {Notification, notificationStore} from "./stores/notification.store";

function initErrorHandler() {
    axios.interceptors.response.use((response) => {
        return response;
    }, function (error) {
        if (error.response) {
            if (error.response.status === 401) {
                console.log('unauthorized, logging out ...');
                authService.logout();
            } else {
                return Promise.reject(error.response.data)
            }
        }

        return Promise.reject(error);
    });
}

function spinnerWrapper(promise) {
    spinnerService.start();
    return promise.then(results => {
        spinnerService.stop();
        return results;
    }).catch(reason => {
        spinnerService.stop();
        return Promise.reject(reason);
    })
}

function spinnerWithErrorHandling(axios) {
    return spinnerWrapper(axios)
        .catch(reason => {
            notificationStore.push(Notification.error(reason.message));
            return Promise.reject(reason);
        })
}

const SpinnerWrapper = {
    get(url) {
        const headers = authService.getAuthHeaders();
        return spinnerWithErrorHandling(axios.get(url, {headers}));
    },
    put(url, payload) {
        const headers = authService.getAuthHeaders();
        return spinnerWithErrorHandling(axios.put(url, payload, {headers}));
    },
    post(url, payload, config) {
        const headers = Object.assign({config}, authService.getAuthHeaders());
        return spinnerWithErrorHandling(axios.post(url, payload, {headers}))
    },
    patch(url, payload) {
        const headers = authService.getAuthHeaders();
        return spinnerWithErrorHandling(axios.patch(url, payload, {headers}));
    },
    delete(url, payload) {
        const headers = authService.getAuthHeaders();
        return spinnerWithErrorHandling(axios.delete(url, { headers: headers, data: payload}));
    }
};

const PageableSpinner = {
    get(url, page, size) {
        return SpinnerWrapper.get(`${url}?page=${page}&size=${size}`)
            .then(results => {
                return new Pageable(results.data);
            });
    }
};

const getEnv = (name, defaultValue) => {
    const env = window._env_[name];
    return env ? env : defaultValue;
};

const getEnvAsBoolean = (name, defaultValue) => {
    return JSON.parse(getEnv(name, defaultValue));
};

Object.freeze(SpinnerWrapper);
Object.freeze(PageableSpinner);

export {initErrorHandler, spinnerWrapper, SpinnerWrapper, PageableSpinner, getEnv, getEnvAsBoolean};

