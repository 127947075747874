import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Notification from "../Notification";
import {Navigation} from "../../components/Navigation";
import {authService} from "../../api/auth.service";
import {Loader} from "../../components/Loader";
import {observer} from "mobx-react";
import {organizationService} from "../../api/organizations.api";
import {Routing} from "./routing";
import Idle from 'react-idle'
import {BannerComponent} from "../../components/banner/banner";
import {Logo} from "../../components/logo/logo";
import {bannerConfig} from "../../config/banner.config";

const logoutUserAfter = 300000;

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUser: null,
            organizations: [],
            currentOrganization: null,
        };

        this.onChangeCurrentOrganization = this.onChangeCurrentOrganization.bind(this);
        this.setInitialState = this.setInitialState.bind(this);
        this.setStateWithOrganizations = this.setStateWithOrganizations.bind(this);
        this.onUserIdle = this.onUserIdle.bind(this);

        this.state.onChangeCurrentOrganization = this.onChangeCurrentOrganization;
    }

    componentDidMount() {
        authService.subscription.subscribe(user => {
            if (user != null) {
                if (user.role === 'USER') {
                    organizationService.getUserOrganizations()
                        .then(organizations => {
                            this.setStateWithOrganizations(user, organizations.data);
                        });
                } else if (user.role === 'ADMIN') {
                    this.setState(state => {
                        return Object.assign(state, {
                            currentUser: user
                        })
                    })
                }
            } else {
                this.setInitialState()
            }
        });
    }

    setStateWithOrganizations(currentUser, organizations) {
        this.setState(state => {
            return Object.assign(state, {
                currentUser: currentUser,
                organizations: organizations,
                currentOrganization: organizations[0]
            });
        });
    }

    setInitialState() {
        this.setState(state => {
            return Object.assign(state, {
                currentUser: null,
                organizations: [],
                currentOrganization: null
            })
        })
    }

    onChangeCurrentOrganization(id) {
        const newOrganization = this.state.organizations.find(organization => {
            return organization.id.toString() === id;
        });

        this.setState(state => {
            return Object.assign(state, {
                currentOrganization: newOrganization,
            })
        })
    }

    onUserIdle(idle) {
        const {currentUser} = this.state;
        if (currentUser !== null && idle) {
            authService.logout()
                .then(() => {
                    window.location.replace("/login");
                });
        }
    }

    render() {
        const {
            currentUser,
            organizations,
            currentOrganization
        } = this.state;

        if (authService.getUser() === null && window.location.pathname === '/' ) {
            window.location.replace("/login");
        }

        return (
            <Router>
                <Idle onChange={this.onUserIdle}
                      timeout={logoutUserAfter} />
                <Loader />
                <Container>
                    <Row>
                        <Col>
                            <Notification />
                        </Col>
                    </Row>
                </Container>
                <header className={"header"}>
                    <Container>
                                <Logo />
                                <Navigation currentOrganization={currentOrganization} />
                    </Container>
                    <BannerComponent items={bannerConfig} {...this.state} />
                </header>
                <main>
                    <Container>
                        <Row>
                            <Col>
                                <Routing currentUser={currentUser}
                                         organizations={organizations}
                                         currentOrganization={currentOrganization}/>
                            </Col>
                        </Row>
                    </Container>
                </main>
            </Router>
        );
    }
}

export default observer(App);