import React from 'react';
import './style.scss';
import Spinner from "react-bootstrap/Spinner";
import {BehaviorSubject} from "rxjs";

let i = 0;
const spinnerSubscription = new BehaviorSubject(false);

class Loader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        }
    }

    componentDidMount() {
        spinnerSubscription.subscribe(event => {
            this.setState({isLoading: event});
        })
    }

    render() {
        const isLoading = i > 0;
        return (
            <div className={`loader-container ${isLoading ? 'active ': ''}`}>
                <Spinner animation="border" className={"spinner"}/>
            </div>
        )
    }
}

spinnerSubscription.subscribe(value => {
    if (value) {
        i++;
    } else {
        if (i>0) {
            i--;
        }
    }
});

const spinnerService = {
    start: () => {
        spinnerSubscription.next(true);
    },
    stop: () => {
        spinnerSubscription.next(false);
    }
};

export {Loader, spinnerService};
