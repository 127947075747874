import {initErrorHandler, PageableSpinner, SpinnerWrapper} from "../utils";

const api = window._env_.API_URL;

initErrorHandler();

const find = async (organization, type, page, size) => {
    return PageableSpinner.get(`${api}/organizations/${organization}/certificates/${type} `, page, size)
};

const upload = async (organization, payload) => {
    return SpinnerWrapper.post(`${api}/organizations/${organization}/certificates`, payload, {headers: {'content-type': 'multipart/form-data'}});
};

const createCSR = async (organization, payload) => {
    return SpinnerWrapper.post(`${api}/organizations/${organization}/certificates/csr`, payload);
};

const uploadPEM = async (organization, certificate, payload) => {
    return SpinnerWrapper.post(`${api}/organizations/${organization}/certificates/${certificate}`, payload, {headers: {'content-type': 'multipart/form-data'}});
};

export const certificateService = {
    find,
    upload,
    createCSR,
    uploadPEM
};
