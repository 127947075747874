const DEFAULT_SIZE = 30;

class Pageable {
    constructor(props) {
        this.content = props['content'];
        this.empty = props['empty'];
        this.first = props['first'];
        this.last = props['last'];
        this.number = props['number'];
        this.size = props['size'];
        this.totalElements  = props['totalElements'];
        this.totalPages = props['totalPages'];
    }

    static createEmpty() {
        return new Pageable({
            content: [],
            empty: true,
            first: true,
            last: true,
            number: 0,
            size: DEFAULT_SIZE,
            totalElements: 0,
            totalPages: 0
        })
    }
}

Object.freeze(Pageable);

export {Pageable, DEFAULT_SIZE};
